import { useState } from 'react'

import {
  Anchor,
  Grid,
  Heading,
  Modal,
  Table,
} from '@enterprise-ui/canvas-ui-react'

const defaultColDef = { sortable: false }

const StopsModal = ({ load_stop_list, load }) => {
  const loadId = load.load_id
  const tripId = load.trip_id

  const stopEventsColumnDefs = [
    {
      headerName: 'Stop',
      field: 'stop_sequence_number',
      isRowHeader: true,
      width: 70,
    },
    ...(tripId !== 0
      ? [
          {
            headerName: 'Load ID',
            field: 'load_id',
            width: 100,
          },
        ]
      : []),
    {
      headerName: 'Stop Type',
      field: 'stop_type',
      width: 100,
    },
    {
      headerName: 'Location Code',
      field: 'shipping_location_code',
      width: 130,
    },
    {
      headerName: 'Location Name',
      field: 'stop_shipping_location_name',
    },
    {
      headerName: 'Address',
      field: 'stop_shipping_location_street',
    },
  ]

  const mapStops = () => {
    const mappedStops = []

    load_stop_list?.forEach((stop) => {
      mappedStops.push({
        load_id: stop.stop_load_id,
        stop_sequence_number: stop.stop_sequence_number,
        stop_type: stop.stop_type,
        shipping_location_code: stop.shipping_location_code,
        stop_shipping_location_name: stop.stop_shipping_location_name,
        stop_shipping_location_street: {
          cellValue: stop.stop_shipping_location_street,
          cellDisplay: (
            <p>
              {`${stop.stop_shipping_location_street}`} <br />
              {`${stop.stop_shipping_location_city}, ${stop.stop_shipping_location_state_code}`}
            </p>
          ),
        },
      })
    })

    return mappedStops
  }

  const stops = mapStops()

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Anchor
        aria-label={`Stops for Load ${load.load_id}`}
        as="span"
        href="#"
        onClick={openModal}
      >
        {load_stop_list?.length}
      </Anchor>
      <Modal onRefuse={closeModal} isVisible={isOpen}>
        <Grid.Container className="hc-pa-xl" direction="column">
          <Grid.Item>
            <Heading size={3}>{`Stops for ${tripId === 0 ? 'Load' : 'Trip'} ${
              tripId === 0 ? loadId : tripId
            }`}</Heading>
          </Grid.Item>
          <Grid.Item>
            {stops?.length > 0 ? (
              <Table
                name="Stops"
                data={{
                  columnDefs: stopEventsColumnDefs,
                  defaultColDef: defaultColDef,
                  rowData: stops,
                }}
              />
            ) : (
              <p>No stops.</p>
            )}
          </Grid.Item>
        </Grid.Container>
      </Modal>
    </>
  )
}

export default StopsModal
