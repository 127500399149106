import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { chain, isEmpty, omit } from 'lodash'

import {
  Button,
  ButtonGroup,
  Grid,
  Layout,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilterIcon } from '@enterprise-ui/icons'

import { ProgressOverlay } from '@dlm/common'
import '@dlm/common/dist/esm/css/styles.css'
import '../../stylesheets/customStyles.scss'

import SearchFilterDrawer from './SearchFilterDrawer'
import LoadBoardTable from './components/LoadBoardTable'

import useUser from '../../common/hooks/useUser'

import boardService from './services/boardService'
import bidService from './services/bidService'

import { startCase } from '../../common/util/stringUtil'

import { DEFAULT_LOAD_BOARD_FILTERS } from './constants/loadBoardConstants'
import {
  AUCTION_HISTORY,
  AUCTION_ELIGIBLE_LOADS,
  CARRIER_USER,
  LOAD_BOARD_CRITERIA_ID_MAP,
  INTERNAL_USER,
  LOADS_IN_AUCTION,
} from './constants/CriteriaIds'

import apiConfig from '../../config/apiConfig'

import featureFlag from '../../common/util/featureFlag'
import {
  carrierUserIsNotEligibleToGetLoads,
  mapToCarrierSpecificSearchFilters,
} from './util/loadBoardUtil'
import useBusinessPartner from '../../common/hooks/useBusinessPartner'

const DEFAULT_SORT_COLUMN = 'load_id'

const LoadBoard = () => {
  const { accessToken, email, access } = useUser()
  const businessPartnerDetails = useBusinessPartner()

  // Configure any axios interceptors here
  // Usually we set interceptors globally, but this needs to be inside the component to work with federation
  axios.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = apiConfig.api.key
    // Usually populated by praxis by default, but doesn't work if accessed from parent mfe app
    config.headers['Authorization'] =
      accessToken && accessToken.includes('Bearer')
        ? accessToken
        : `Bearer ${accessToken}`
    return config
  })

  const makeToast = ToastProvider.useToaster()

  const userType = access.isCarrier ? CARRIER_USER : INTERNAL_USER

  // This is a state variable only to support the user type switching in dev/stg for testing purposes
  // Do not explicitly call setDevUserType in any other use case.
  const [devUserType, setDevUserType] = useState(userType)

  const [loadSearchFilters, setLoadSearchFilters] = useState({
    page: 1,
    per_page: 20,
    sort_by: DEFAULT_SORT_COLUMN,
    isDescendingSort: true,
    selected_filters: [],
    ...DEFAULT_LOAD_BOARD_FILTERS,
  })

  const [selectedView, setSelectedView] = useState(
    devUserType === CARRIER_USER ? LOADS_IN_AUCTION : AUCTION_ELIGIBLE_LOADS,
  )
  const [selectedRows, setSelectedRows] = useState([])
  const [loadData, setLoadData] = useState(null)
  const [loadCount, setLoadCount] = useState(0)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const onPaginationChange = (pageNum, pageSize) => {
    setLoadSearchFilters((prevFilters) => ({
      ...prevFilters,
      page: pageNum,
      per_page: pageSize,
    }))
  }

  const onSearchFilterChange = (newFilters) => {
    const updatedSelectedFilters = chain(newFilters)
      .pick(Object.keys(DEFAULT_LOAD_BOARD_FILTERS))
      .omitBy(isEmpty)
      .keys()
      .value()

    // Need to re-instate pagination and sort properties as filter drawer strips them
    setLoadSearchFilters({
      ...newFilters,
      page: 1,
      selected_filters: updatedSelectedFilters,
      per_page: loadSearchFilters.per_page,
      sort_by: loadSearchFilters.sort_by,
      isDescendingSort: loadSearchFilters.isDescendingSort,
    })
  }

  const onSortChange = (direction, column) => {
    const sortBy = direction === null ? 'load_id' : column
    const isDescendingSort = direction === null ? true : direction === 'dsc'

    // Perform sort change only if sort parameters have changed
    if (
      !(
        sortBy === loadSearchFilters.sort_by &&
        isDescendingSort === loadSearchFilters.isDescendingSort
      )
    ) {
      setLoadSearchFilters({
        ...loadSearchFilters,
        sort_by: sortBy,
        isDescendingSort: isDescendingSort,
      })
    }
  }

  const changeView = (e) => {
    setSelectedRows([])
    setSelectedView(e.target.id)
    // Reset sorting and go to page 1
    setLoadSearchFilters({
      ...loadSearchFilters,
      sort_by: 'load_id',
      isDescendingSort: true,
      page: 1,
    })
  }

  const onRowSelect = (e) => {
    setSelectedRows(e)
  }

  const getLoads = useCallback(() => {
    const { area, move_type } = loadSearchFilters

    let carrierSpecificLoadSearchFilters = {}
    if (access.isCarrier) {
      if (
        carrierUserIsNotEligibleToGetLoads(selectedView, businessPartnerDetails)
      ) {
        setLoadCount(0)
        setLoadData([])
        return
      }
      carrierSpecificLoadSearchFilters = mapToCarrierSpecificSearchFilters(
        selectedView,
        businessPartnerDetails,
      )
    }

    setInProgress(true)
    boardService
      .getLoadData(
        omit(
          {
            ...loadSearchFilters,
            ...carrierSpecificLoadSearchFilters,
            criteria_id:
              LOAD_BOARD_CRITERIA_ID_MAP[selectedView][devUserType][area][
                move_type
              ],
          },
          'selected_filters',
        ),
      )
      .then((resp) => {
        setLoadCount(resp.row_count)
        setLoadData(resp.load_list)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching load details',
        })
      })
      .finally(() => {
        setInProgress(false)
      })
  }, [
    loadSearchFilters,
    selectedView,
    makeToast,
    devUserType,
    access,
    businessPartnerDetails,
  ])

  const updateBid = useCallback(
    (bid) => {
      bid.active = !bid.active
      bid.update_by = email
      bidService
        .updateBid(bid)
        .then(() => {
          getLoads()
        })
        .catch((err) => {
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: `Error updating bid. ${err.message}.`,
          })
        })
    },
    [makeToast, email, getLoads],
  )

  const refreshBoard = () => {
    getLoads()
    setSelectedRows([])
  }

  useEffect(() => {
    getLoads()
  }, [getLoads])

  const exportCSV = (type) => {
    const { area, move_type } = loadSearchFilters
    const { criteria_id } = {
      criteria_id:
        LOAD_BOARD_CRITERIA_ID_MAP[selectedView][devUserType][area][move_type],
    }
    if (loadCount <= 500) {
      boardService
        .getCSVReport(loadSearchFilters, email, type, criteria_id)
        .then(() => {
          makeToast({
            type: 'success',
            heading: 'Success',
            message: `Successfully sent CSV report to email: ${email}`,
          })
        })
        .catch((error) => {
          console.error(error)
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error creating CSV report',
          })
        })
    } else {
      makeToast({
        type: 'error',
        heading: `Load count from query is too large: ${loadCount}, please narrow your search filters`,
        message: 'Error creating CSV report',
      })
    }
  }

  return (
    <>
      <ProgressOverlay inProgress={inProgress} />
      <SearchFilterDrawer
        isVisible={filtersVisible}
        onRequestClose={() => {
          setFiltersVisible(false)
        }}
        onChange={onSearchFilterChange}
        selectedView={selectedView}
        userType={devUserType}
      />
      <Layout.Body data-testid="loadBoard" includeRail>
        <Grid.Container justify="space-between" className="hc-mb-normal">
          <Grid.Item>
            <ButtonGroup>
              {devUserType === INTERNAL_USER && (
                <Button
                  id={AUCTION_ELIGIBLE_LOADS}
                  onClick={changeView}
                  type={
                    selectedView === AUCTION_ELIGIBLE_LOADS
                      ? 'primary'
                      : 'secondary'
                  }
                >
                  {startCase(AUCTION_ELIGIBLE_LOADS)}
                </Button>
              )}
              <Button
                id={LOADS_IN_AUCTION}
                onClick={changeView}
                type={
                  selectedView === LOADS_IN_AUCTION ? 'primary' : 'secondary'
                }
              >
                {startCase(LOADS_IN_AUCTION)}
              </Button>
              <Button
                id={AUCTION_HISTORY}
                onClick={changeView}
                type={
                  selectedView === AUCTION_HISTORY ? 'primary' : 'secondary'
                }
              >
                {startCase(AUCTION_HISTORY)}
              </Button>
            </ButtonGroup>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container>
              {
                // This button provides option to switch between internal and carrier view
                // TODO - SCAC associations for carrier view
                // TODO - Possibly move this to DLM so can be used across all MFEs (Would require some effort)
                userType === INTERNAL_USER &&
                  featureFlag(
                    ['prod'],
                    <Grid.Item>
                      <Grid.Container>
                        <Grid.Item>
                          <p className="centering-container">
                            Current User Type:&nbsp;
                            <p className="hc-clr-interactive">
                              {startCase(devUserType)}
                            </p>
                          </p>
                        </Grid.Item>
                        <Grid.Item>
                          <Tooltip
                            content={`Switch to ${
                              devUserType === INTERNAL_USER
                                ? CARRIER_USER
                                : INTERNAL_USER
                            } view`}
                            location="top"
                          >
                            <span>
                              <Button
                                onClick={() => {
                                  if (selectedView === AUCTION_ELIGIBLE_LOADS) {
                                    setSelectedView(LOADS_IN_AUCTION)
                                  }
                                  setDevUserType(
                                    devUserType === INTERNAL_USER
                                      ? CARRIER_USER
                                      : INTERNAL_USER,
                                  )
                                }}
                              >
                                Switch
                              </Button>
                            </span>
                          </Tooltip>
                        </Grid.Item>
                      </Grid.Container>
                    </Grid.Item>,
                  )
              }
              <Grid.Item>
                <Button onClick={() => setFiltersVisible(true)}>
                  <EnterpriseIcon
                    icon={FilterIcon}
                    size="sm"
                    style={{ marginRight: 5 }}
                  />
                  Show Filters
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Item xs={12}>
            {loadData && (
              <LoadBoardTable
                loads={loadData}
                loadCount={loadCount}
                pageNum={loadSearchFilters.page}
                pageSize={loadSearchFilters.per_page}
                selectedView={selectedView}
                userType={devUserType}
                onUpdateBid={updateBid}
                onRefresh={refreshBoard}
                onPaginationChange={onPaginationChange}
                onSortChange={onSortChange}
                onRowSelect={onRowSelect}
                selectedRows={selectedRows}
                onExport={exportCSV}
              />
            )}
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}

export default LoadBoard
