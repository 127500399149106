import { useState } from 'react'
import { chain, isEmpty, map } from 'lodash'

import {
  Anchor,
  Grid,
  Heading,
  Input,
  Modal,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CheckCircleFilledIcon } from '@enterprise-ui/icons'

import { formatDate } from '../../../common/util/dateUtil'
import { toWrappedText } from '../../../common/util/wrappedTextCell'

const defaultColDef = { sortable: false }
const getBaseBidsColumnDefs = (isCarrier = true) => {
  const estimatedFuelCostColumnDefs = isCarrier
    ? []
    : [
        {
          field: 'estimated_fuel_cost',
          headerName: 'Estimated Fuel Cost',
          width: 200,
        },
        {
          field: 'total_amount',
          headerName: 'Total Amount',
          width: 200,
        },
      ]

  return [
    {
      field: 'scac',
      headerName: 'SCAC',
      isRowHeader: true,
      width: 150,
    },
    {
      field: 'bid_amount',
      headerName: 'Bid Amount',
      width: 150,
    },
    ...estimatedFuelCostColumnDefs,
    {
      field: 'service_code',
      headerName: 'Service Code',
    },
    {
      field: 'date_submitted',
      headerName: 'Submitted Date',
    },
  ]
}

const bidErrorsColumnDefs = [
  {
    field: 'scac',
    headerName: 'SCAC',
    isRowHeader: true,
    width: 65,
  },
  {
    field: 'date_submitted',
    headerName: 'Submitted Date',
    width: 80,
  },
  {
    field: 'error_messages',
    headerName: 'Error Messages',
  },
]

const getSecondaryRatesColumnDefs = (isCarrier = true) => {
  const estimatedFuelCostColumnDefs = isCarrier
    ? []
    : [
        {
          field: 'estimated_fuel_cost',
          headerName: 'Estimated Fuel Cost',
          width: 200,
        },
        {
          field: 'total_amount',
          headerName: 'Total Amount',
          width: 200,
        },
      ]

  return [
    {
      field: 'scac',
      headerName: 'SCAC',
      isRowHeader: true,
      width: 100,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      width: 200,
    },
    ...estimatedFuelCostColumnDefs,
    {
      field: 'selected',
      headerName: 'Selected',
      width: 80,
    },
  ]
}

const BidsModal = ({ load, isCarrier, onUpdateBid, isAuctionEnded }) => {
  const bids = load?.bids
  const secondaryRates = load?.secondary_rates?.rates
  const currentScac = load?.current_scac
  const mapBids = () => {
    const mappedBidsWithErrors = chain(bids)
      .filter((bid) => !isEmpty(bid.error_messages))
      .orderBy(['scac', 'date_submitted'], ['asc', 'desc'])
      .map((bid) => ({
        scac: bid.scac,
        date_submitted: bid.date_submitted,
        error_messages: toWrappedText(bid.error_messages.join('; ')),
      }))
      .value()

    const validBids = chain(bids)
      .filter((bid) => isEmpty(bid.error_messages))
      .orderBy(['scac', 'date_submitted'], ['asc', 'desc'])
      .value()

    const mappedValidBids = map(validBids, (bid, i) => {
      const newBidder = i === 0 || bid.scac !== validBids[i - 1].scac
      const totalAmount = bid.bid_amount + (bid.fuel_cost ?? 0)
      const fixedTotalAmount = totalAmount.toFixed(2)

      return {
        scac: newBidder ? bid.scac : '',
        bid_amount: {
          cellValue: bid.bid_amount,
          cellDisplay: `$${bid.bid_amount}`,
        },
        estimated_fuel_cost: {
          cellValue: bid.fuel_cost ?? 0,
          cellDisplay: bid.fuel_cost ? `$${bid.fuel_cost}` : '',
        },
        total_amount: {
          cellValue: fixedTotalAmount,
          cellDisplay: `$${fixedTotalAmount}`,
        },
        service_code: bid.service_code,
        date_submitted: formatDate(bid.date_submitted),
        active: {
          cellDisplay: (
            <Input.Checkbox
              id={bid.bid_id}
              data-testid={bid.bid_id}
              checked={bid.active}
              disabled={!newBidder}
              onChange={() => {
                onUpdateBid(bid)
              }}
            />
          ),
          callValue: bid.active,
        },
        winner: {
          cellDisplay: bid.winner && (
            <EnterpriseIcon
              data-testid={bid.bid_id}
              className="hc-clr-interactive"
              icon={CheckCircleFilledIcon}
            />
          ),
          callValue: bid.winner,
        },
      }
    })

    return [mappedValidBids, mappedBidsWithErrors]
  }

  const mapSecondaryRates = () => {
    const mappedSecondaryRates = []

    secondaryRates?.forEach((rate) => {
      const totalAmount = rate.rate + (rate.fuel_cost ?? 0)
      const fixedTotalAmount = totalAmount.toFixed(2)

      mappedSecondaryRates.push({
        scac: rate.scac,
        rate: {
          cellValue: rate.rate,
          cellDisplay: `$${rate.rate}`,
        },
        estimated_fuel_cost: {
          cellValue: rate.fuel_cost ?? 0,
          cellDisplay: rate.fuel_cost ? `$${rate.fuel_cost}` : '',
        },
        total_amount: {
          cellValue: fixedTotalAmount,
          cellDisplay: `$${fixedTotalAmount}`,
        },
        selected: {
          cellDisplay: rate.scac === currentScac && (
            <EnterpriseIcon
              data-testid={currentScac}
              className="hc-clr-interactive"
              icon={CheckCircleFilledIcon}
            />
          ),
        },
      })
    })

    return mappedSecondaryRates
  }

  const [validBids, bidErrors] = mapBids()

  const [isOpen, setIsOpen] = useState(false)

  const mappedSecondaryRates = mapSecondaryRates()

  const openModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }

  const bidsColumnDefs = isAuctionEnded
    ? [
        ...getBaseBidsColumnDefs(isCarrier),
        { field: 'winner', headerName: 'Winner', width: 100 },
      ]
    : [
        ...getBaseBidsColumnDefs(isCarrier),
        { field: 'active', headerName: 'Active', width: 100 },
      ]

  return (
    <>
      <Anchor
        data-testid={`bids_for_${bids && bids[0].load_id}`}
        aria-label={`Bids for Load ${bids && bids[0]?.load_id}`}
        as="span"
        href="#"
        onClick={openModal}
      >
        {bids?.length}
      </Anchor>
      <Modal onRefuse={closeModal} isVisible={isOpen} size="expanded">
        <Grid.Container direction="column">
          <Grid.Container className="hc-pa-xl" direction="column">
            <Grid.Item>
              <Heading size={3}>
                Bids for Load {bids && bids[0]?.load_id}
              </Heading>
            </Grid.Item>
          </Grid.Container>

          <Grid.Container className="hc-pv-sm hc-ph-xl" direction="column">
            <Grid.Item className="hc-pt-none hc-pb-none">
              <Heading size={4} className="hc-pb-xs">
                Bids
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container
            className="hc-pt-sm hc-pr-xl hc-pb-xl hc-pl-xl full-width-table"
            direction="column"
          >
            <Grid.Item className="hc-pt-none bids-modal-table">
              {validBids?.length > 0 ? (
                <Table
                  name="Bids"
                  data={{
                    columnDefs: bidsColumnDefs,
                    defaultColDef: defaultColDef,
                    rowData: validBids,
                  }}
                />
              ) : (
                <p>No bids.</p>
              )}
            </Grid.Item>
          </Grid.Container>

          <Grid.Container className="hc-pv-sm hc-ph-xl" direction="column">
            <Grid.Item className="hc-pt-none hc-pb-none">
              <Heading size={4} className="hc-pb-xs">
                Secondary Rates
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container
            className="hc-pt-sm hc-pr-xl hc-pb-xl hc-pl-xl full-width-table"
            direction="column"
          >
            <Grid.Item className="hc-pt-none bids-modal-table">
              {mappedSecondaryRates?.length !== 0 ? (
                <Table
                  name="Secondary Rates"
                  data={{
                    columnDefs: getSecondaryRatesColumnDefs(isCarrier),
                    defaultColDef: defaultColDef,
                    rowData: mappedSecondaryRates,
                  }}
                />
              ) : (
                <p>No secondary rates.</p>
              )}
            </Grid.Item>
          </Grid.Container>

          <Grid.Container className="hc-pv-sm hc-ph-xl" direction="column">
            <Grid.Item className="hc-pt-none hc-pb-none">
              <Heading size={4} className="hc-pb-xs">
                Errors
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container
            className="hc-pt-sm hc-pr-xl hc-pb-xl hc-pl-xl full-width-table"
            direction="column"
          >
            <Grid.Item className="hc-pt-none bids-modal-table">
              {bidErrors?.length > 0 ? (
                <Table
                  name="Errors"
                  data={{
                    columnDefs: bidErrorsColumnDefs,
                    defaultColDef: defaultColDef,
                    rowData: bidErrors,
                  }}
                />
              ) : (
                <p>No bid errors.</p>
              )}
            </Grid.Item>
          </Grid.Container>
        </Grid.Container>
      </Modal>
    </>
  )
}

export default BidsModal
