const date_options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date)
}

export const formatDate = (dateString) => {
  const date = new Date(dateString)

  if (isValidDate(date)) {
    return date.toLocaleString('en-US', date_options).split('T')[0]
  }

  return ''
}

export const formatSearchFilterDate = (dateString) => {
  const date = new Date(dateString)
  if (isValidDate(date)) {
    return date.toISOString().split('T')[0]
  }
  return ''
}
