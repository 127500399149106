import { BrowserRouter as Router } from 'react-router-dom'
import { concat } from 'lodash'

import { AuthProvider } from '@praxis/component-auth'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'

import { ProtectedElement } from '@dlm/common'

import { Main } from './views/Layout/Main'

import './stylesheets/customStyles.scss'
import apiConfig from './config/apiConfig'

const permissions = apiConfig.auth.requiredPermissions

const App = () => {
  return (
    <ToastProvider location="top">
      <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
      <Router>
        <AuthProvider {...apiConfig.auth}>
          <ProtectedElement
            allowed={concat(
              permissions.user,
              permissions.admin,
              permissions.carrier,
              permissions.auction,
            )}
          >
            <Main />
          </ProtectedElement>
        </AuthProvider>
      </Router>
    </ToastProvider>
  )
}

export default App
