import { Input, ToastProvider } from '@enterprise-ui/canvas-ui-react'

import useUser from '../../../common/hooks/useUser'

import boardService from '../services/boardService'

import { AUCTION_ELIGIBLE_LOADS } from '../constants/CriteriaIds'

const HazmatControl = ({ load, view, onRefresh }) => {
  const { hazmat, load_id: loadId } = load
  const {
    email,
    access: { isAdmin, isAuction },
  } = useUser()
  const makeToast = ToastProvider.useToaster()

  const onChange = (e) => {
    boardService
      .updateHazmat(loadId, e.target.checked, email)
      .then((resp) => {
        onRefresh()
        makeToast({
          type: resp.error_message ? 'error' : 'success',
          heading: resp.error_message ? 'Update Error' : 'Success',
          message: resp.error_message
            ? resp.error_message
            : resp.response_message,
        })
      })
      .catch((err) => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: `Error updating hazmat eligibility. ${err.message}.`,
        })
      })
  }

  return (
    <Input.Checkbox
      id={`hazmat-${loadId}`}
      aria-label={`Hazmat Control for Load ID: ${loadId}`}
      type="checkbox"
      checked={hazmat}
      value={hazmat}
      disabled={view !== AUCTION_ELIGIBLE_LOADS || !(isAdmin || isAuction)}
      onChange={onChange}
    />
  )
}

export default HazmatControl
