import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { get, isEmpty, isInteger, reduce, toNumber, intersection } from 'lodash'

import {
  Card,
  Button,
  Divider,
  Drawer,
  Grid,
  Heading,
  Input,
  Layout,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelCircleIcon,
  PlusCircleIcon,
  SaveIcon,
} from '@enterprise-ui/icons'

import {
  ConfirmActionModal,
  FormAutocomplete,
  FormMultiText,
  FormTextField,
  FormToggle,
  ProgressOverlay,
} from '@dlm/common'

import useUser from '../../../common/hooks/useUser'

import businessPartnerService from '../../LoadBoard/services/businessPartnerService'
import carrierEligibilityService from '../services/carrierEligibilityService'
import criteriaService from '../../../common/service/criteriaService'

import { startCase } from '../../../common/util/stringUtil'

import { AUCTION_ELIGIBLE_CATEGORIES_CRITERIA } from '../../LoadBoard/constants/CriteriaIds'

const CarrierEligibilityCreate = ({ onRefresh }) => {
  const [eligibleSubcategories, setEligibleSubcategories] = useState(null)
  const [selectedBusinessPartner, setSelectedBusinessPartner] = useState(null)
  const [inProgress, setInProgress] = useState(false)

  const [isCreatePageVisible, setIsCreatePageVisible] = useState(false)
  const [expenseLocationsFieldEnabled, setExpenseLocationsFieldEnabled] =
    useState(false)
  const openCreatePage = () => setIsCreatePageVisible(true)
  const closeCreatePage = () => setIsCreatePageVisible(false)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmModalType, setConfirmModalType] = useState('exit')
  const closeConfirmationModal = () => {
    setShowConfirmModal(false)
  }

  const { email } = useUser()
  const makeToast = ToastProvider.useToaster()

  const defaultValues = {
    scac: '',
    max_wins_per_start_date: '',
    auction_eligible_load_sub_categories: [],
    expense_load_eligible_locations: [],
    api_rate: false,
    api_bid: false,
    manual_auction: false,
  }

  const formContext = useForm({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: defaultValues,
  })

  const {
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    setValue,
    resetField,
    reset,
    trigger,
    clearErrors,
  } = formContext

  const showCreateEligibilityForm = () => {
    if (!eligibleSubcategories) {
      criteriaService
        .getCriteria(AUCTION_ELIGIBLE_CATEGORIES_CRITERIA)
        .then((response) => {
          setEligibleSubcategories(
            reduce(
              response?.predicate_json?.load_predicate?.category_list,
              (result, category) => {
                category?.sub_types?.forEach((subCategory) => {
                  result.push({
                    id: subCategory,
                    value: subCategory,
                    category_type: category.type,
                    label: startCase(subCategory),
                  })
                })
                return result
              },
              [],
            ),
          )
          openCreatePage()
        })
        .catch(() => {
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Unexpected Server Error. Please Try Again.',
          })
        })
    } else {
      openCreatePage()
    }
  }

  const createCarrier = async (values) => {
    setInProgress(true)

    const { scac } = values
    carrierEligibilityService
      .createCarrier(
        values,
        selectedBusinessPartner.business_partner_details.business_partner_name,
        email,
      )
      .then(() => {
        makeToast({
          type: 'success',
          message: `Successfully Created Eligibility for: ${scac}`,
        })
        onRefresh()
        closeCreatePage()
      })
      .catch(() => {
        makeToast({
          type: 'error',
          message: `Failed to Create Eligibility for: ${scac}`,
        })
      })
      .finally(() => {
        closeConfirmationModal()
        setInProgress(false)
      })
  }

  const handleExpenseLocationsField = (subcategoryFieldValue) => {
    if (containsNoExpenseSubcategories(subcategoryFieldValue)) {
      clearErrors('expense_load_eligible_locations')
      resetField('expense_load_eligible_locations')
      setExpenseLocationsFieldEnabled(false)
    } else {
      trigger('expense_load_eligible_locations')
      setExpenseLocationsFieldEnabled(true)
    }
  }

  const containsNoExpenseSubcategories = (subcategoryFieldValue) => {
    return isEmpty(
      intersection(
        subcategoryFieldValue?.map((subcategory) => subcategory.value),
        eligibleSubcategories
          ?.filter((subcategory) => subcategory.category_type === 'EXPENSE')
          .map((subcategory) => subcategory.value),
      ),
    )
  }

  const handleScacChange = (e) => {
    setSelectedBusinessPartner(null)
    setValue('scac', e.target.value.toUpperCase())
    const newScac = e.target.value.toUpperCase()
    if (newScac !== '') {
      setInProgress(true)
      businessPartnerService
        .getCarrierDetails(newScac)
        .then((response) => {
          const businessPartnerName = get(
            response,
            'business_partner_details.business_partner_name',
          )

          if (isEmpty(businessPartnerName)) {
            setError('scac', {
              type: 'custom',
              message: `${newScac} is not configured in VMM.`,
            })
            setSelectedBusinessPartner(null)
          } else if (!isEmpty(get(response, 'carrier_details'))) {
            setError('scac', {
              type: 'custom',
              message: `${newScac} is already configured. Please use Edit screen to make changes.`,
            })
            setSelectedBusinessPartner(null)
          } else {
            setSelectedBusinessPartner(response)
          }
        })
        .catch(() => {
          setError('scac', {
            type: 'custom',
            message:
              'Failed to get business partner details. Please try again.',
          })
          setSelectedBusinessPartner(null)
        })
        .finally(() => {
          setInProgress(false)
        })
    }
  }

  const resetForm = () => {
    closeConfirmationModal()
    reset(defaultValues)
  }

  const returnToCarrierEligibility = () => {
    closeConfirmationModal()
    closeCreatePage()
  }

  const confirmModalMetadata = {
    reset: {
      headingText: 'Reset form?',
      challengeText: 'All unsaved changes will be lost.',
      confirmButtonText: 'Reset',
      cancelButtonText: 'Cancel',
      confirmButtonAction: resetForm,
      cancelButtonAction: closeConfirmationModal,
    },
    exit: {
      headingText: 'Return to Carrier Eligibility?',
      challengeText: 'All unsaved changes will be lost.',
      confirmButtonText: 'Return',
      cancelButtonText: 'Cancel',
      confirmButtonAction: returnToCarrierEligibility,
      cancelButtonAction: closeConfirmationModal,
    },
    save: {
      headingText: 'Confirm Create?',
      challengeText:
        'This action is not reversible and will go into effect immediately.',
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      confirmButtonAction: handleSubmit(createCarrier),
      cancelButtonAction: closeConfirmationModal,
    },
  }

  const handleResetButtonClicked = () => {
    setConfirmModalType('reset')
    setExpenseLocationsFieldEnabled(false)
    setShowConfirmModal(true)
  }

  const handleSaveButtonClicked = () => {
    setConfirmModalType('save')
    setShowConfirmModal(true)
  }

  const handleReturnButtonClicked = () => {
    if (isDirty) {
      setConfirmModalType('exit')
      setShowConfirmModal(true)
    } else {
      returnToCarrierEligibility()
    }
  }

  return (
    <>
      {/*TODO - Overlay currently doesn't display over a drawer. Fix zIndex in dlm-common*/}
      <ProgressOverlay inProgress={inProgress} />
      <Tooltip
        content="Create Eligibility Configuration for a New Carrier SCAC"
        location="top"
      >
        <span>
          <Button
            type="primary"
            aria-label="Carrier Eligibility Create Button"
            onClick={showCreateEligibilityForm}
          >
            <EnterpriseIcon icon={PlusCircleIcon} />
            <div className="hc-pl-dense">New Carrier SCAC</div>
          </Button>
        </span>
      </Tooltip>
      <Drawer
        isVisible={isCreatePageVisible}
        hasOverlay={false}
        onRequestClose={handleReturnButtonClicked}
        headingText={
          <Button
            className="hc-ma-dense"
            type="primary"
            onClick={handleReturnButtonClicked}
          >
            Return to Carrier Eligibility
          </Button>
        }
        xs={12}
        style={{ height: 'calc(100vh - 56px)', top: '56px' }}
      >
        {isCreatePageVisible && (
          <>
            <Layout.Body data-testid="carrierEligibilityCreate" includeRail>
              <Grid.Container>
                <Grid.Item xs={12}>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Card className="hc-pa-expanded">
                      <Grid.Container justify="space-between">
                        <Grid.Item>
                          <Heading size={3}>New Carrier SCAC</Heading>
                        </Grid.Item>
                        <Grid.Item>
                          <Grid.Container spacing="dense">
                            <Grid.Item>
                              <Tooltip content="Save Changes" location="top">
                                <Button
                                  iconOnly
                                  type="ghost"
                                  disabled={!isDirty || !isEmpty(errors)}
                                  onClick={handleSaveButtonClicked}
                                  aria-label="Save Changes"
                                >
                                  <EnterpriseIcon icon={SaveIcon} size="lg" />
                                </Button>
                              </Tooltip>
                            </Grid.Item>
                            <Grid.Item>
                              <Tooltip content="Reset Form" location="top">
                                <Button
                                  iconOnly
                                  type="ghost"
                                  onClick={handleResetButtonClicked}
                                  disabled={!isDirty}
                                  aria-label="Reset Form"
                                >
                                  <EnterpriseIcon icon={CancelCircleIcon} />
                                </Button>
                              </Tooltip>
                            </Grid.Item>
                          </Grid.Container>
                        </Grid.Item>
                        <Grid.Item xs={12}>
                          <Divider />
                        </Grid.Item>
                        <Grid.Item xs={12}>
                          <Grid.Container direction="row">
                            <Grid.Item xs={4}>
                              <FormTextField
                                name="scac"
                                label="SCAC"
                                style={{ textTransform: 'uppercase' }}
                                formContext={formContext}
                                onBlur={handleScacChange}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    handleScacChange(e)
                                  }
                                }}
                              />
                            </Grid.Item>
                            <Grid.Item xs={8}>
                              <Input.Label>
                                Selected Business Partner
                              </Input.Label>
                              <Input.Text
                                disabled
                                value={
                                  get(
                                    selectedBusinessPartner,
                                    'business_partner_details.business_partner_name',
                                  ) ?? ''
                                }
                              />
                            </Grid.Item>
                          </Grid.Container>
                        </Grid.Item>
                        <Grid.Item xs={12}>
                          <Divider />
                        </Grid.Item>
                        <Grid.Item
                          xs={12}
                          style={{
                            visibility: isEmpty(selectedBusinessPartner)
                              ? 'hidden'
                              : 'visible',
                          }}
                        >
                          <Grid.Container direction="row">
                            <Grid.Item xs={4}>
                              <Grid.Container
                                direction="column"
                                spacing="dense"
                              >
                                <Grid.Item>
                                  <FormToggle
                                    formContext={formContext}
                                    name="api_rate"
                                    label="API Rating"
                                    disabled={isEmpty(selectedBusinessPartner)}
                                  />
                                </Grid.Item>
                                <Grid.Item>
                                  <FormToggle
                                    formContext={formContext}
                                    name="api_bid"
                                    label="API Auction"
                                    disabled={isEmpty(selectedBusinessPartner)}
                                  />
                                </Grid.Item>
                                <Grid.Item>
                                  <FormToggle
                                    formContext={formContext}
                                    name="manual_auction"
                                    label="Manual Auction"
                                    disabled={isEmpty(selectedBusinessPartner)}
                                  />
                                </Grid.Item>
                              </Grid.Container>
                            </Grid.Item>
                            <Grid.Item xs={8}>
                              <Grid.Container direction="column">
                                <Grid.Item>
                                  <FormAutocomplete
                                    formContext={formContext}
                                    multiselect
                                    name="auction_eligible_load_sub_categories"
                                    label="Auction Eligible Categories"
                                    disabled={isEmpty(selectedBusinessPartner)}
                                    onChange={(id, value) => {
                                      handleExpenseLocationsField(value)
                                    }}
                                    disableFieldInfo
                                    options={eligibleSubcategories}
                                  />
                                </Grid.Item>
                                <Grid.Item>
                                  <FormMultiText
                                    formContext={formContext}
                                    name="expense_load_eligible_locations"
                                    label="Expense Load Eligible Locations"
                                    rules={{
                                      validate: {
                                        isValid: (value) => {
                                          if (
                                            isEmpty(value) &&
                                            expenseLocationsFieldEnabled
                                          ) {
                                            return 'Field must not be empty'
                                          }
                                        },
                                      },
                                    }}
                                    disabled={!expenseLocationsFieldEnabled}
                                  />
                                </Grid.Item>
                                <Grid.Item>
                                  <FormTextField
                                    formContext={formContext}
                                    name="max_wins_per_start_date"
                                    label="Max Auction Wins Per Day"
                                    disabled={isEmpty(selectedBusinessPartner)}
                                    disableFieldInfo={false}
                                    rules={{
                                      validate: {
                                        isValid: (value) => {
                                          if (isEmpty(value)) {
                                            return
                                          }
                                          const numValue = toNumber(value)
                                          if (
                                            !(
                                              isInteger(toNumber(numValue)) &&
                                              numValue > 0
                                            )
                                          ) {
                                            return 'Enter a Positive Integer'
                                          }
                                        },
                                      },
                                    }}
                                  />
                                </Grid.Item>
                              </Grid.Container>
                            </Grid.Item>
                          </Grid.Container>
                        </Grid.Item>
                      </Grid.Container>
                    </Card>
                  </form>
                </Grid.Item>
              </Grid.Container>
            </Layout.Body>
          </>
        )}
      </Drawer>
      <ConfirmActionModal
        isVisible={showConfirmModal}
        {...confirmModalMetadata[confirmModalType]}
      />
    </>
  )
}

export default CarrierEligibilityCreate
