import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Grid, Heading, Modal } from '@enterprise-ui/canvas-ui-react'

import { FormTextField } from '@dlm/common'

const AUCTION_END = 'AUCTION_END'
const CONFIRM = 'CONFIRM'

const AuctionModal = ({
  loads,
  auctionDuration,
  setAuctionDuration,
  confirmButtonAction,
  cancelButtonAction,
  isVisible,
}) => {
  const defaultValues = {
    duration_hours: Math.floor(auctionDuration / 60),
    duration_minutes: auctionDuration % 60,
  }
  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: defaultValues,
  })

  const { getValues, reset, handleSubmit } = formContext

  const [onPage, setOnPage] = useState(AUCTION_END)
  const [durationDisplay, setDurationDisplay] = useState('0h 00m')

  const handleBackPressed = () => {
    if (onPage === CONFIRM) {
      setOnPage(AUCTION_END)
    }
  }

  const handleNextPressed = () => {
    if (onPage === AUCTION_END) {
      setOnPage(CONFIRM)
    }
    const values = getValues()
    const minutes = parseInt(values.duration_minutes) || 0
    const hours = parseInt(values.duration_hours) || 0
    setAuctionDuration(hours * 60 + minutes)
    setDurationDisplay(`${hours}h ${minutes < 10 ? '0' : ''}${minutes}m`)
  }

  const handleSendToAuctionPressed = () => {
    setOnPage(AUCTION_END)
    reset()
    confirmButtonAction()
  }

  const handleCancelPressed = () => {
    setOnPage(AUCTION_END)
    reset()
    cancelButtonAction()
  }

  return (
    <Modal
      size="dense"
      isVisible={isVisible}
      onRefuse={handleCancelPressed}
      footer={
        <Grid.Container
          spacing="dense"
          justify="flex-end"
          className="hc-pl-normal hc-pr-normal hc-pb-normal"
        >
          <Grid.Item>
            <Button
              type="secondary"
              onClick={
                onPage === AUCTION_END ? handleCancelPressed : handleBackPressed
              }
            >
              {onPage === AUCTION_END ? 'Cancel' : 'Back'}
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button
              type="primary"
              onClick={
                onPage === AUCTION_END
                  ? handleSubmit(handleNextPressed)
                  : handleSendToAuctionPressed
              }
            >
              {onPage === AUCTION_END
                ? 'Next'
                : `Send ${loads.length} Load${
                    loads.length > 1 ? 's' : ''
                  } to Auction`}
            </Button>
          </Grid.Item>
        </Grid.Container>
      }
    >
      <Grid.Container direction="column" className="hc-pa-expanded">
        {onPage === AUCTION_END && (
          <>
            <Grid.Item>
              <Heading size={3}>Set auction duration (Optional)</Heading>
            </Grid.Item>
            <Grid.Item className="hc-ma-md">
              <form onSubmit={(e) => e.preventDefault()}>
                <Grid.Container spacing="dense">
                  <Grid.Item>
                    <FormTextField
                      formContext={formContext}
                      name="duration_hours"
                      label="Hours"
                      aria-label="duration_hours"
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <FormTextField
                      formContext={formContext}
                      name="duration_minutes"
                      label="Minutes"
                      aria-label="duration_minutes"
                    />
                  </Grid.Item>
                </Grid.Container>
              </form>
            </Grid.Item>
          </>
        )}
        {onPage === CONFIRM && (
          <>
            <Grid.Item>
              <Heading size={3}>Confirm Auction Start</Heading>
            </Grid.Item>
            <Grid.Item className="hc-ma-md">
              <p>{`Are you sure you want to start auction for load${
                loads.length > 1 ? 's' : ''
              }:`}</p>
              <pre>{`    ${loads.join(', ')}`}</pre>
              <br />
              <p className="hc-clr-alert">{`Auction would end in ${durationDisplay}.`}</p>
            </Grid.Item>
          </>
        )}
      </Grid.Container>
    </Modal>
  )
}

export default AuctionModal
