import { useEffect, useState } from 'react'

import ruleService from '../../views/LoadBoard/services/ruleService'

const useRules = () => {
  const [rules, setRules] = useState([])

  useEffect(() => {
    ruleService.getRules().then((data) => setRules(data))
  }, [])

  return rules
}

export default useRules
