import { useState } from 'react'

import { Button, ToastProvider } from '@enterprise-ui/canvas-ui-react'

import { ConfirmActionModal } from '@dlm/common'

import boardService from '../services/boardService'
import useUser from '../../../common/hooks/useUser'

import { separateLoadAndTripIDs } from '../util/loadBoardUtil'

const EndAuctionButton = ({ loads, selectedRows, onRefresh }) => {
  const makeToast = ToastProvider.useToaster()
  const { email } = useUser()

  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)

  const openConfirmModal = () => {
    setConfirmModalVisible(true)
  }

  const closeConfirmModal = () => {
    setConfirmModalVisible(false)
  }

  const endAuction = () => {
    const [loadIds, tripIds] = separateLoadAndTripIDs(loads, selectedRows)

    boardService
      .endAuction({
        auction_duration_minutes: null,
        load_ids: loadIds,
        trip_ids: tripIds,
        submit_user: email,
      })
      .then(() => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Selected auctions successfully ended',
        })
        onRefresh()
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Error',
          message: 'Failed to end selected auctions. Please try again',
        })
      })
      .finally(() => {
        closeConfirmModal()
      })
  }

  return (
    <>
      <ConfirmActionModal
        headingText="Confirm Auction End"
        challengeText={`Are you sure you want to end auction for load${
          selectedRows.length > 1 ? 's' : ''
        } ${selectedRows.join(', ')}`}
        confirmButtonText="Confirm"
        confirmButtonAction={endAuction}
        cancelButtonText="Cancel"
        cancelButtonAction={closeConfirmModal}
        isVisible={isConfirmModalVisible}
      />
      <Button
        id="endAuction"
        aria-label="End Auction Button"
        type="primary"
        onClick={openConfirmModal}
        disabled={selectedRows?.length < 1}
      >
        {selectedRows?.length < 1
          ? 'End Auction for Loads'
          : `End Auction for ${selectedRows?.length} Load${
              selectedRows?.length > 1 ? 's' : ''
            }`}
      </Button>
    </>
  )
}

export default EndAuctionButton
