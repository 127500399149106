import axios from 'axios'
import { createParams } from '../../../common/util/queryStringUtil'
import apiConfig from '../../../config/apiConfig'
import { formatSearchFilterDate } from '../../../common/util/dateUtil'

const getLoadData = async (loadSearchFilters) => {
  const params = {
    ...loadSearchFilters,
    auction_from_date: formatSearchFilterDate(
      loadSearchFilters.auction_from_date,
    ),
    auction_to_date: formatSearchFilterDate(loadSearchFilters.auction_to_date),
  }

  const { data } = await axios.get(
    `${apiConfig.api.edge}/loads/middle_mile_loads`,
    {
      params: createParams(params),
    },
  )

  return data
}

const startAuction = async (payload) => {
  const { data } = await axios.post(
    `${apiConfig.api.edge}/auction/submit`,
    payload,
  )
  return data
}

const endAuction = async (payload) => {
  const { data } = await axios.put(`${apiConfig.api.edge}/auction/end`, payload)
  return data
}

const updateHazmat = async (loadId, hazmat, email) => {
  const { data } = await axios.put(`${apiConfig.api.edge}/loads`, {
    loads: [
      {
        load_id: loadId,
        hazmat,
      },
    ],
    submit_user: email,
  })

  return data
}

const getCSVReport = async (loadSearchFilters, user, type, criteria_id) => {
  const params = formatToCSVReportParams(
    loadSearchFilters,
    user,
    type,
    criteria_id,
  )
  const { data } = await axios.get(`${apiConfig.api.edge}/loads/report`, {
    params: createParams(params),
  })
  return data
}

export function formatToCSVReportParams(
  loadSearchFilters,
  user,
  type,
  criteria_id,
) {
  return {
    isDescendingSort: loadSearchFilters.is_descending_sort,
    load_id_list: loadSearchFilters.load_id_list,
    status_list: loadSearchFilters.status_list,
    trip_id_list: loadSearchFilters.trip_id_list,
    auction_from_date: formatSearchFilterDate(
      loadSearchFilters.auction_from_date,
    ),
    auction_to_date: formatSearchFilterDate(loadSearchFilters.auction_to_date),
    criteria_id: criteria_id,
    email: user,
    report_type: type,
  }
}

export default {
  getLoadData,
  getCSVReport,
  startAuction,
  endAuction,
  updateHazmat,
}
