import axios from 'axios'

import { createParams } from '../../../common/util/queryStringUtil'

import apiConfig from '../../../config/apiConfig'

const getBusinessPartnerDetails = async (businessPartnerId) => {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/business_partner_scacs/${businessPartnerId}`,
  )
  return data
}

const getCarrierDetails = async (scac) => {
  const { data } = await axios.get(`${apiConfig.api.edge}/carriers/search`, {
    params: createParams({ scac }),
  })
  return data
}

export default {
  getBusinessPartnerDetails,
  getCarrierDetails,
}
