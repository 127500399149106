import { Link } from 'react-router-dom'
import {
  Button,
  Grid,
  Heading,
  Layout,
  TargetLogo,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { LogoutIcon } from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'
import { useState } from 'react'
import { ConfirmActionModal } from '@dlm/common'

export const Header = () => {
  const auth = useAuth()
  const { logout, session } = auth

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false)

  const openLogoutModal = () => {
    setIsLogoutModalVisible(true)
  }

  const closeLogoutModal = () => {
    setIsLogoutModalVisible(false)
  }

  return (
    <>
      <ConfirmActionModal
        headingText="Are you sure you want to logout?"
        challengeText="Unsaved changes will be lost."
        confirmButtonText="Logout"
        confirmButtonAction={logout}
        cancelButtonText="Cancel"
        cancelButtonAction={closeLogoutModal}
        isVisible={isLogoutModalVisible}
      />
      <Layout.Header className="topPage-layout-header" includeRail={true}>
        <Grid.Container justify="space-between">
          <Grid.Item>
            <Grid.Container spacing="dense">
              <Grid.Item>
                <Link to="/">
                  <TargetLogo size="expanded" color="white" />
                </Link>
              </Grid.Item>
              <Grid.Item>
                <Link to="/">
                  <Heading size={4} className="white-header">
                    Auction
                  </Heading>
                </Link>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container>
              <Layout.GlobalActions>
                <Grid.Container justify="flex-end" align="center">
                  <Grid.Item>
                    <Grid.Container direction="column" align="flex-end">
                      <p className="hc-fs-md white-header">
                        {session.userInfo.fullName}
                      </p>
                    </Grid.Container>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      aria-label="Click to logout"
                      iconOnly
                      data-testid="headerLogoutButton"
                      className="headerIconButton"
                      onClick={openLogoutModal}
                    >
                      <EnterpriseIcon icon={LogoutIcon} size="md" />
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </Layout.GlobalActions>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
    </>
  )
}
