import axios from 'axios'
import apiConfig from '../../../config/apiConfig'

const getRulesData = async () => {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/rules/category/AUCTION`,
  )
  return data
}

export default {
  getRulesData,
}
