import {
  AUCTION_HISTORY,
  AUCTION_ELIGIBLE_LOADS,
  CARRIER_USER,
  INTERNAL_USER,
  LOADS_IN_AUCTION,
} from './CriteriaIds'

const LOAD_TRIP_ID = {
  headerName: 'Load ID / Trip ID',
  field: 'load_id/trip_id',
  sortBy: 'load_id',
  isRowHeader: true,
  sortable: true,
  defaultSort: 'dsc',
}

const STATUS = {
  headerName: 'Status',
  field: 'status',
  sortBy: 'status',
  sortable: true,
  width: 200,
}

const MOVE_TYPE = {
  headerName: 'Move Type',
  field: 'move_type',
  width: 170,
}

const ORIGIN = {
  headerName: 'Origin',
  width: 280,
  field: 'origin',
  sortBy: 'origin_id',
  sortable: true,
}

const DESTINATION = {
  headerName: 'Destination',
  width: 280,
  field: 'destination',
  sortBy: 'destination_id',
  sortable: true,
}

const STOPS_IN_TRANSIT = {
  headerName: 'Stops',
  field: 'stops',
  sortBy: 'stops',
  width: 100,
}

const EXPECTED_PICKUP = {
  headerName: 'Expected Pickup',
  width: 170,
  field: 'expected_pickup',
  sortBy: 'load_start_date',
  sortable: true,
}

const EXPECTED_DELIVERY = {
  headerName: 'Expected Delivery',
  field: 'expected_delivery',
  sortBy: 'load_end_date',
  sortable: true,
}

const DELIVERY_TYPE = {
  headerName: 'Delivery Type',
  field: 'delivery_type',
}

const PRIMARY_SCAC_RATE = {
  headerName: 'Primary SCAC - Rate',
  width: 200,
  field: 'primary_scac_rate',
  sortBy: 'primary_scac',
  sortable: true,
}

const CURRENT_SCAC_RATE = {
  headerName: 'Current SCAC - Rate',
  width: 200,
  field: 'current_scac_rate',
  sortBy: 'current_scac',
  sortable: true,
}

const TOTAL_BIDS = {
  headerName: 'Bids',
  field: 'total_bids',
  width: 70,
}

const COMMODITY = {
  headerName: 'Commodity',
  field: 'commodity',
}

const SERVICE_CODE = {
  headerName: 'Service Code',
  field: 'service_code',
}

const WEIGHT = {
  headerName: 'Weight',
  width: 130,
  field: 'weight',
}

const VOLUME = {
  headerName: 'Volume',
  width: 130,
  field: 'volume',
}

const TOTAL_MILEAGE = {
  headerName: 'Total Mileage',
  field: 'total_mileage',
}

const POWER_ONLY_MOVE = {
  headerName: 'Power Only Move',
  field: 'power_only_move',
}

const HAZMAT = {
  headerName: 'Hazmat',
  field: 'hazmat',
}

const BID_INPUT = {
  headerName: 'Bid Amount',
  field: 'bid_amount',
}

const SCAC_INPUT = {
  headerName: 'Bidding SCAC',
  field: 'bidding_scac',
}

const TIME_REMAINING = {
  headerName: 'Time Remaining',
  field: 'time_remaining',
  width: 140,
}

const AUCTION_START = {
  headerName: 'Auction Start',
  field: 'auction_start',
  sortBy: 'auction_start_dt',
  sortable: true,
}

const AUCTION_END = {
  headerName: 'Auction End',
  field: 'auction_end',
  sortBy: 'auction_end_dt',
  sortable: true,
}

const EQUIPMENT_TYPE = {
  headerName: 'Equipment Type',
  field: 'equipment_type',
}

export const LOAD_BOARD_COLUMNS = {
  [INTERNAL_USER]: {
    [AUCTION_ELIGIBLE_LOADS]: [
      LOAD_TRIP_ID,
      STATUS,
      MOVE_TYPE,
      ORIGIN,
      DESTINATION,
      STOPS_IN_TRANSIT,
      EXPECTED_PICKUP,
      EXPECTED_DELIVERY,
      DELIVERY_TYPE,
      PRIMARY_SCAC_RATE,
      CURRENT_SCAC_RATE,
      COMMODITY,
      SERVICE_CODE,
      WEIGHT,
      VOLUME,
      TOTAL_MILEAGE,
      POWER_ONLY_MOVE,
      HAZMAT,
    ],
    [LOADS_IN_AUCTION]: [
      LOAD_TRIP_ID,
      STATUS,
      TOTAL_BIDS,
      MOVE_TYPE,
      ORIGIN,
      DESTINATION,
      STOPS_IN_TRANSIT,
      EXPECTED_PICKUP,
      EXPECTED_DELIVERY,
      DELIVERY_TYPE,
      PRIMARY_SCAC_RATE,
      CURRENT_SCAC_RATE,
      TIME_REMAINING,
      COMMODITY,
      SERVICE_CODE,
      WEIGHT,
      VOLUME,
      TOTAL_MILEAGE,
      POWER_ONLY_MOVE,
      HAZMAT,
    ],
    [AUCTION_HISTORY]: [
      LOAD_TRIP_ID,
      STATUS,
      TOTAL_BIDS,
      MOVE_TYPE,
      ORIGIN,
      DESTINATION,
      STOPS_IN_TRANSIT,
      EXPECTED_PICKUP,
      EXPECTED_DELIVERY,
      DELIVERY_TYPE,
      PRIMARY_SCAC_RATE,
      CURRENT_SCAC_RATE,
      AUCTION_START,
      AUCTION_END,
      COMMODITY,
      SERVICE_CODE,
      WEIGHT,
      VOLUME,
    ],
  },
  [CARRIER_USER]: {
    [AUCTION_ELIGIBLE_LOADS]: [],
    [LOADS_IN_AUCTION]: [
      LOAD_TRIP_ID,
      TIME_REMAINING,
      MOVE_TYPE,
      ORIGIN,
      DESTINATION,
      STOPS_IN_TRANSIT,
      EXPECTED_PICKUP,
      EXPECTED_DELIVERY,
      BID_INPUT,
      SCAC_INPUT,
      EQUIPMENT_TYPE,
      COMMODITY,
      SERVICE_CODE,
      DELIVERY_TYPE,
      WEIGHT,
      POWER_ONLY_MOVE,
      HAZMAT,
    ],
    [AUCTION_HISTORY]: [
      LOAD_TRIP_ID,
      TOTAL_BIDS,
      MOVE_TYPE,
      EXPECTED_PICKUP,
      EXPECTED_DELIVERY,
      ORIGIN,
      DESTINATION,
      STOPS_IN_TRANSIT,
      AUCTION_START,
      AUCTION_END,
      COMMODITY,
      SERVICE_CODE,
      DELIVERY_TYPE,
      POWER_ONLY_MOVE,
    ],
  },
}

export const DEFAULT_COLUMN = {
  sortable: false,
  width: 170,
}
