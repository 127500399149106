import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  chain,
  intersection,
  isEmpty,
  isInteger,
  map,
  reduce,
  set,
  toNumber,
} from 'lodash'
import { FormMultiText } from '@dlm/common'

import {
  Card,
  Button,
  Divider,
  ExpandableSection,
  Grid,
  Modal,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { PencilIcon } from '@enterprise-ui/icons'

import {
  ConfirmActionModal,
  FormAutocomplete,
  FormTextField,
  FormToggle,
} from '@dlm/common'

import useUser from '../../../common/hooks/useUser'
import carrierEligibilityService from '../services/carrierEligibilityService'
import criteriaService from '../../../common/service/criteriaService'

import { startCase } from '../../../common/util/stringUtil'

import { AUCTION_ELIGIBLE_CATEGORIES_CRITERIA } from '../../LoadBoard/constants/CriteriaIds'

const resetExpenseLoadEligibleLocations = (carriers) => {
  return carriers.reduce((map, carrier) => {
    const expenseLocations = carrier?.carrier_details?.expense_locations
    if (expenseLocations && expenseLocations?.length !== 0) {
      return map.set(`${carrier.scac}-expense_load_eligible_locations`, false)
    } else {
      return map.set(`${carrier.scac}-expense_load_eligible_locations`, true)
    }
  }, new Map())
}

const CarrierEligibilityEdit = ({ carriers, onRefresh }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmModalType, setConfirmModalType] = useState('save')
  const [eligibleSubcategories, setEligibleSubcategories] = useState(null)
  const [expenseLocationFieldDisabled, setExpenseLoadEligibleLocations] =
    useState(resetExpenseLoadEligibleLocations(carriers))

  const { email } = useUser()
  const makeToast = ToastProvider.useToaster()

  const handleExpenseLocationsField = (subcategoryFieldValues, scac) => {
    if (containsNoExpenseSubcategories(subcategoryFieldValues)) {
      setValue(`${scac}-expense_load_eligible_locations`, [])
      clearErrors(`${scac}-expense_load_eligible_locations`)
      expenseLocationFieldDisabled.set(
        `${scac}-expense_load_eligible_locations`,
        true,
      )
    } else {
      trigger(`${scac}-expense_load_eligible_locations`)
      expenseLocationFieldDisabled.set(
        `${scac}-expense_load_eligible_locations`,
        false,
      )
    }
  }

  const containsNoExpenseSubcategories = (subcategoryFieldValue) => {
    return isEmpty(
      intersection(
        subcategoryFieldValue?.map((subcategory) => subcategory.value),
        eligibleSubcategories
          ?.filter((subcategory) => subcategory.category_type === 'EXPENSE')
          .map((subcategory) => subcategory.value),
      ),
    )
  }

  const defaultValues = useMemo(() => {
    return reduce(
      carriers,
      (result, carrier) => {
        const { scac, api_rate, api_bid, manual_auction } = carrier
        result[`${scac}-api_rate`] = api_rate
        result[`${scac}-api_bid`] = api_bid
        result[`${scac}-manual_auction`] = manual_auction
        result[`${scac}-max_wins_per_day`] =
          carrier?.carrier_details?.auction_details?.max_wins_per_start_date ??
          ''
        result[`${scac}-subcategories`] = chain(
          carrier?.carrier_details?.auction_eligible_load_sub_categories,
        )
          .omitBy(isEmpty)
          .map((subcategory) => ({
            id: subcategory,
            value: subcategory,
            category_type: subcategory,
            label: startCase(subcategory),
          }))
          .value()
        result[`${scac}-expense_load_eligible_locations`] =
          carrier?.carrier_details?.expense_locations ?? []

        return result
      },
      {},
    )
  }, [carriers])

  const formContext = useForm({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: defaultValues,
  })

  const {
    formState: { dirtyFields, errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    trigger,
  } = formContext

  const showEditForm = () => {
    if (!eligibleSubcategories) {
      criteriaService
        .getCriteria(AUCTION_ELIGIBLE_CATEGORIES_CRITERIA)
        .then((response) => {
          setEligibleSubcategories(
            reduce(
              response?.predicate_json?.load_predicate?.category_list,
              (result, category) => {
                category?.sub_types?.forEach((subCategory) => {
                  result.push({
                    id: subCategory,
                    value: subCategory,
                    category_type: category.type,
                    label: startCase(subCategory),
                  })
                })
                return result
              },
              [],
            ),
          )
          setIsVisible(true)
        })
        .catch(() => {
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Unexpected Server Error. Please Try Again.',
          })
        })
    } else {
      setIsVisible(true)
    }
  }
  const closeEditForm = () => {
    reset(defaultValues)
    setShowConfirmModal(false)
    setIsVisible(false)
  }

  const resetForm = () => {
    reset(defaultValues)
    setExpenseLoadEligibleLocations(resetExpenseLoadEligibleLocations(carriers))
  }

  const askConfirmation = (type) => {
    switch (type) {
      case 'exit':
        if (!isDirty) {
          closeEditForm()
          return
        }
      // eslint-disable-next-line no-fallthrough
      case 'save':
        setConfirmModalType(type)
        setShowConfirmModal(true)
        break
      default:
        throw new Error('Invalid Confirmation Type')
    }
  }

  const saveUpdates = async (values) => {
    setShowConfirmModal(false)
    const updatedSCACs = chain(dirtyFields)
      .keys()
      .map((field) => field.split('-')[0])
      .uniq()
      .value()

    const carrierUpdatePayloads = reduce(
      values,
      (result, value, field) => {
        const [fieldSCAC, fieldType] = field.split('-')
        if (updatedSCACs.includes(fieldSCAC)) {
          switch (fieldType) {
            case 'max_wins_per_day':
              set(
                result,
                `${fieldSCAC}.carrier_details.auction_details.max_wins_per_start_date`,
                value,
              )
              break
            case 'subcategories':
              set(
                result,
                `${fieldSCAC}.carrier_details.auction_eligible_load_sub_categories`,
                map(value, (selection) => selection.value),
              )
              break
            case 'expense_load_eligible_locations':
              set(
                result,
                `${fieldSCAC}.carrier_details.expense_locations`,
                value,
              )
              break
            default:
              set(result, `${fieldSCAC}.${fieldType}`, value)
          }
          result[fieldSCAC].update_by = email
        }
        return result
      },
      {},
    )

    await Promise.all(
      updatedSCACs.map((scac) => {
        return carrierEligibilityService
          .updateCarrierData(scac, carrierUpdatePayloads[scac])
          .then(() => {
            makeToast({
              type: 'success',
              message: `Successfully Updated Details for: ${scac}`,
            })
          })
          .catch(() => {
            makeToast({
              type: 'error',
              message: `Failed to Update Details for: ${scac}`,
            })
          })
      }),
    )
    onRefresh()
    setIsVisible(false)
  }

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues, isVisible])

  const carrierName = carriers[0].name
  return (
    <>
      <Tooltip content="Edit Carrier Eligibility" location="top">
        <span>
          <Button
            type="ghost"
            iconOnly
            aria-label={`Carrier Eligibility Edit Button for ${carrierName}`}
            onClick={showEditForm}
          >
            <EnterpriseIcon icon={PencilIcon} />
          </Button>
        </span>
      </Tooltip>
      <form>
        <Modal
          isVisible={isVisible}
          scrollBehavior="contained"
          onRefuse={() => askConfirmation('exit')}
          headingText={
            <>
              <h2 className="hc-pa-normal">{carrierName}</h2>
              <Divider className="hc-pt-normal hc-pl-normal" />
            </>
          }
          size="expanded"
          footer={
            <>
              <Divider className="hc-ph-normal hc-pb-expanded" />
              <Grid.Container
                spacing="dense"
                justify="flex-end"
                className="hc-ph-normal hc-pb-normal"
              >
                <Grid.Item>
                  <Button
                    type="secondary"
                    size="expanded"
                    className="hc-ph-xl"
                    disabled={!isDirty}
                    onClick={resetForm}
                  >
                    Reset
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    type="primary"
                    size="expanded"
                    className="hc-ph-2x"
                    onClick={() => askConfirmation('save')}
                    disabled={!isDirty || !isEmpty(errors)}
                  >
                    Save
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </>
          }
        >
          {isVisible && (
            <div className="hc-ph-expanded hc-pv-normal">
              {map(carriers, (carrier, i) => {
                const { scac } = carrier

                return (
                  <Card key={i} className="hc-pa-normal hc-mb-expanded">
                    <ExpandableSection startExpanded padding="dense">
                      <h2>{carrier.scac}</h2>
                      <ExpandableSection.Content>
                        <Divider className="hc-pb-normal" />
                        <div className="hc-pa-normal">
                          <Grid.Container direction="row">
                            <Grid.Item xs={4}>
                              <Grid.Container
                                direction="column"
                                spacing="dense"
                              >
                                <Grid.Item>
                                  <FormToggle
                                    formContext={formContext}
                                    name={`${scac}-api_rate`}
                                    label="API Rating"
                                  />
                                </Grid.Item>
                                <Grid.Item>
                                  <FormToggle
                                    formContext={formContext}
                                    name={`${scac}-api_bid`}
                                    label="API Auction"
                                  />
                                </Grid.Item>
                                <Grid.Item>
                                  <FormToggle
                                    formContext={formContext}
                                    name={`${scac}-manual_auction`}
                                    label="Manual Auction"
                                  />
                                </Grid.Item>
                              </Grid.Container>
                            </Grid.Item>
                            <Grid.Item xs={8}>
                              <Grid.Container direction="column">
                                <Grid.Item>
                                  <FormAutocomplete
                                    formContext={formContext}
                                    multiselect
                                    name={`${scac}-subcategories`}
                                    onChange={(id, value) => {
                                      handleExpenseLocationsField(value, scac)
                                    }}
                                    label="Auction Eligible Categories"
                                    options={eligibleSubcategories}
                                  />
                                </Grid.Item>
                                <Grid.Item>
                                  <FormMultiText
                                    formContext={formContext}
                                    name={`${scac}-expense_load_eligible_locations`}
                                    label="Expense Load Eligible Locations"
                                    rules={{
                                      validate: {
                                        isValid: (value) => {
                                          if (
                                            isEmpty(value) &&
                                            !expenseLocationFieldDisabled.get(
                                              `${scac}-expense_load_eligible_locations`,
                                            )
                                          ) {
                                            return 'Field must not be empty'
                                          }
                                        },
                                      },
                                    }}
                                    disabled={expenseLocationFieldDisabled.get(
                                      `${scac}-expense_load_eligible_locations`,
                                    )}
                                  />
                                </Grid.Item>
                                <Grid.Item>
                                  <FormTextField
                                    formContext={formContext}
                                    name={`${scac}-max_wins_per_day`}
                                    label="Max Auction Wins Per Day"
                                    disableFieldInfo={false}
                                    rules={{
                                      validate: {
                                        isValid: (value) => {
                                          if (isEmpty(value)) {
                                            return
                                          }
                                          const numValue = toNumber(value)
                                          if (
                                            !(
                                              isInteger(toNumber(numValue)) &&
                                              numValue > 0
                                            )
                                          ) {
                                            return 'Enter a Positive Integer'
                                          }
                                        },
                                      },
                                    }}
                                  />
                                </Grid.Item>
                              </Grid.Container>
                            </Grid.Item>
                          </Grid.Container>
                        </div>
                      </ExpandableSection.Content>
                    </ExpandableSection>
                  </Card>
                )
              })}
            </div>
          )}
        </Modal>
      </form>
      <ConfirmActionModal
        isVisible={showConfirmModal}
        headingText={
          confirmModalType === 'exit' ? 'Confirm Exit' : 'Confirm Save'
        }
        challengeText={
          confirmModalType === 'exit'
            ? 'All unsaved changes would be lost. Are you sure you want to exit?'
            : 'The changes would go into effect immediately. Are you sure you want to save?'
        }
        confirmButtonText={confirmModalType === 'exit' ? 'Exit' : 'Save'}
        confirmButtonAction={
          confirmModalType === 'exit'
            ? closeEditForm
            : handleSubmit(saveUpdates)
        }
        cancelButtonText="Cancel"
        cancelButtonAction={() => setShowConfirmModal(false)}
      />
    </>
  )
}

export default CarrierEligibilityEdit
