import { filter, flatMap, uniq, get, isEmpty } from 'lodash'
import { AUCTION_HISTORY, LOADS_IN_AUCTION } from '../constants/CriteriaIds'

export const getLoadStatusColor = (loadStatus) => {
  let loadStatusColor

  switch (loadStatus) {
    case 'COMPLETED':
    case 'AUCTION_COMPLETED':
      loadStatusColor = 'success'
      break

    case 'INVALID':
    case 'RATING_ERROR':
    case 'AUCTION_ERROR':
      loadStatusColor = 'error'
      break

    case 'NO_WINNERS':
      loadStatusColor = 'alert'
      break

    default:
      loadStatusColor = 'default'
  }

  return loadStatusColor
}

export const findAuctionEligibleSCACs = (load, carriers) => {
  return filter(
    carriers,
    (carrier) =>
      carrier.manual_auction === true &&
      carrier.carrier_details &&
      carrier.carrier_details.auction_eligible_load_categories &&
      carrier.carrier_details.auction_eligible_load_categories.some(
        (category) => category === load.category,
      ) &&
      carrier.carrier_details.auction_eligible_load_sub_categories.some(
        (category) => category === load.subcategory,
      ),
  ).map((carrier) => carrier.scac)

  // TODO - Optional dummy return for FMDT-4281
}

export const separateLoadAndTripIDs = (loads, ids = []) => {
  const loadIds = filter(loads, (load) => ids.includes(load.load_id)).map(
    (load) => load.load_id,
  )
  const tripIds = filter(loads, (load) => ids.includes(load.trip_id)).map(
    (load) => load.trip_id,
  )

  return [loadIds, tripIds]
}

export const findAuctionEligibleCarriersForSearch = (carriers) => {
  return filter(
    carriers,
    (carrier) => carrier.manual_auction || carrier.api_bid,
  )
}

export const getCarrierSubcategories = (carriers) => {
  return uniq(
    flatMap(
      carriers,
      (carrier) =>
        carrier?.carrier_details?.auction_eligible_load_sub_categories || [],
    ),
  )
}

/*
if selected view
  -> is LOADS_IN_AUCTION, we only search for loads for scacs that have api auction and manual auction enabled
  -> is AUCTION_HISTORY, we search for all loads based on aggregated categories/subcategories and carriers scacs
 */
export const mapToCarrierSpecificSearchFilters = (
  selectedView,
  businessPartnerDetails,
) => {
  let carriers = get(businessPartnerDetails, 'carriers') || []

  if (selectedView === LOADS_IN_AUCTION) {
    let carriersEligibleForAuctionSearch =
      findAuctionEligibleCarriersForSearch(carriers)

    return {
      subcategory_list: getCarrierSubcategories(
        carriersEligibleForAuctionSearch,
      ),
      bid_scac_search_list: carriersEligibleForAuctionSearch.map(
        (carrier) => carrier.scac,
      ),
      exclude_scac: carriersEligibleForAuctionSearch.map(
        (carrier) => carrier.scac,
      ),
    }
  } else if (selectedView === AUCTION_HISTORY) {
    return {
      subcategory_list: getCarrierSubcategories(carriers),
      bid_scac_search_list: get(businessPartnerDetails, 'scac_codes') || [],
      exclude_scac: get(businessPartnerDetails, 'scac_codes') || [],
    }
  }
}

export const carrierUserIsNotEligibleToGetLoads = (
  selectedView,
  businessPartnerDetails,
) => {
  let carriers = get(businessPartnerDetails, 'carriers') || []

  if (
    isEmpty(businessPartnerDetails) ||
    isEmpty(carriers) ||
    isEmpty(getCarrierSubcategories(carriers))
  ) {
    return true
  }

  if (selectedView === LOADS_IN_AUCTION) {
    let carrierScacsEligibleForAuctionSearch =
      findAuctionEligibleCarriersForSearch(carriers).map(
        (carrier) => carrier.scac,
      )
    if (isEmpty(carrierScacsEligibleForAuctionSearch)) return true
  }

  return false
}
