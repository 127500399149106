import AuctionConfigurationTable from './components/AuctionConfigurationTable'
import { Grid, Layout, ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useCallback, useEffect, useState } from 'react'
import auctionConfigurationService from './services/auctionConfigurationService'
import { ProgressOverlay } from '@dlm/common'
import useUser from '../../common/hooks/useUser'
import apiConfig from '../../config/apiConfig'
import axios from 'axios'

const AuctionConfiguration = () => {
  const makeToast = ToastProvider.useToaster()
  const { accessToken } = useUser()

  // Configure any axios interceptors here
  // Usually we set interceptors globally, but this needs to be inside the component to work with federation
  axios.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = apiConfig.api.key
    // Usually populated by praxis by default, but doesn't work if accessed from parent mfe app
    config.headers['Authorization'] =
      accessToken && accessToken.includes('Bearer')
        ? accessToken
        : `Bearer ${accessToken}`
    return config
  })

  const [inProgress, setInProgress] = useState(false)
  const [ruleData, setRuleData] = useState(null)
  const [ruleCount, setRuleCount] = useState(0)

  const getRulesData = useCallback(() => {
    setInProgress(true)
    auctionConfigurationService
      .getRulesData()
      .then((data) => {
        setRuleData(data)
        setRuleCount(data.length)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching rule data',
        })
        setRuleData([])
      })
      .finally(() => {
        setInProgress(false)
      })
  }, [makeToast])

  useEffect(() => {
    getRulesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ProgressOverlay inProgress={inProgress} />
      <Layout.Body data-testid="auctionConfiguration" includeRail>
        <Grid.Container>
          <Grid.Item xs={12}>
            <AuctionConfigurationTable
              ruleData={ruleData}
              ruleCount={ruleCount}
              onRefresh={getRulesData}
            />
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}

export default AuctionConfiguration
