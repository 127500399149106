import { AREA_FIRST_MILE, MOVE_TYPE_ALL } from './CriteriaIds'

export const DEFAULT_LOAD_BOARD_FILTERS = {
  area: AREA_FIRST_MILE,
  move_type: MOVE_TYPE_ALL,
  status_list: [],
  load_id_list: [],
  trip_id_list: [],
  auction_from_date: '',
  auction_to_date: '',
}

export const LOAD_STATUS_LIST = [
  'TENDERED',
  'TENDER_ACCEPTED',
  'TENDER_REJECTED',
  'TENDER_CANCELED',
  'IN_TRANSIT',
  'COMPLETED',
  'CANCELED',
  'AUCTION',
  'AUCTION_COMPLETED',
  'RATING_ERROR',
  'NO_WINNERS',
]
