import axios from 'axios'
import apiConfig from '../../../config/apiConfig'

const getRules = async () => {
  const { data } = await axios.get(`${apiConfig.api.edge}/rules`)
  return data
}

export default {
  getRules,
}
