import { Layout } from '@enterprise-ui/canvas-ui-react'

import { MainRouter } from './MainRouter'
import { Header } from './Header'

export const Main = () => {
  return (
    <Layout
      theme="target" // configure theme here
      darkMode="system" // configure dark mode here
      data-testid="mainAuthenticatedLayout"
      fullWidth
    >
      <Header />
      <Layout.Body includeRail className="topPage-layout-body">
        <MainRouter />
      </Layout.Body>
    </Layout>
  )
}
