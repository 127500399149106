import axios from 'axios'

import apiConfig from '../../../config/apiConfig'

const submitBids = (bids) => {
  return axios.post(`${apiConfig.api.edge}/bids/batch`, bids)
}

const updateBid = async (bid) => {
  const { data } = await axios.put(
    `${apiConfig.api.edge}/bid/${bid.bid_id}`,
    bid,
  )

  return data
}
export default {
  submitBids,
  updateBid,
}
