import { useState } from 'react'

import { Button, ToastProvider } from '@enterprise-ui/canvas-ui-react'

import AuctionModal from './AuctionModal'

import boardService from '../services/boardService'
import useUser from '../../../common/hooks/useUser'

import { separateLoadAndTripIDs } from '../util/loadBoardUtil'

const StartAuctionButton = ({ loads, selectedRows, onRefresh }) => {
  const makeToast = ToastProvider.useToaster()
  const { email } = useUser()

  const [auctionDuration, setAuctionDuration] = useState(60)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }

  const openConfirmModal = () => {
    setShowConfirmModal(true)
  }
  const sendLoadsToAuction = () => {
    const [loadIds, tripIds] = separateLoadAndTripIDs(loads, selectedRows)
    boardService
      .startAuction({
        load_ids: loadIds,
        trip_ids: tripIds,
        auction_duration_minutes: auctionDuration,
        submit_user: email,
      })
      .then((resp) => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: resp.response_message,
        })
      })
      .catch((err) => {
        makeToast({
          type: 'error',
          heading: 'Error Submitting Loads to Auction',
          message: err.toString(),
        })
      })
    onRefresh()
    setAuctionDuration(60)
    closeConfirmModal()
  }

  return (
    <>
      <AuctionModal
        loads={selectedRows}
        auctionDuration={auctionDuration}
        setAuctionDuration={setAuctionDuration}
        confirmButtonAction={sendLoadsToAuction}
        cancelButtonAction={closeConfirmModal}
        isVisible={showConfirmModal}
      />
      <Button
        id="sendToAuction"
        aria-label="Start Auction Button"
        type="primary"
        onClick={openConfirmModal}
        disabled={selectedRows?.length < 1}
      >
        {selectedRows?.length < 1
          ? 'Start Auction for Loads'
          : `Start Auction for ${selectedRows?.length} Load${
              selectedRows?.length > 1 ? 's' : ''
            }`}
      </Button>
    </>
  )
}

export default StartAuctionButton
