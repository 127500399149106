import { Route, Routes, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'

import { NotFound, ProtectedElement } from '@dlm/common'

import { ErrorContent } from './ErrorContent'
import LoadBoard from '../LoadBoard/LoadBoard'
import CarrierEligibility from '../CarrierEligibility/CarrierEligibility'
import AuctionConfiguration from '../AuctionConfiguration/AuctionConfiguration'

import apiConfig from '../../config/apiConfig'

const permissions = apiConfig.auth.requiredPermissions

export const MainRouter = () => {
  const location = useLocation()
  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        {/* Application routes*/}
        <Route path="/" element={<LoadBoard />} />

        <Route
          path="/carrier-eligibility"
          element={
            <ProtectedElement allowed={permissions.admin}>
              <CarrierEligibility />
            </ProtectedElement>
          }
        />

        <Route
          path="/auction-configuration"
          element={
            <ProtectedElement allowed={permissions.admin}>
              <AuctionConfiguration />
            </ProtectedElement>
          }
        />

        {/* 404*/}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </ErrorBoundary>
  )
}
