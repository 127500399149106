import businessPartnerService from '../../views/LoadBoard/services/businessPartnerService'
import { useEffect, useState } from 'react'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

import useUser from './useUser'
import { isEmpty } from 'lodash'

const useBusinessPartner = () => {
  const [businessPartnerDetails, setBusinessPartnerDetails] = useState({})

  const makeToast = ToastProvider.useToaster()

  const {
    access: { isCarrier },
    lanId,
  } = useUser()

  useEffect(() => {
    if (isCarrier && isEmpty(businessPartnerDetails)) {
      businessPartnerService
        .getBusinessPartnerDetails(lanId)
        .then((data) => setBusinessPartnerDetails(data))
        .catch((error) => {
          console.log(error)
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error fetching business partner details',
          })
        })
    }
  }, [isCarrier, lanId, makeToast, businessPartnerDetails])

  return businessPartnerDetails
}

export default useBusinessPartner
