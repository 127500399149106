import axios from 'axios'

import apiConfig from '../../../config/apiConfig'
import { createParams } from '../../../common/util/queryStringUtil'

const getRuleData = async (params = {}) => {
  const { data } = await axios.get(`${apiConfig.api.edge}/rules`, {
    params: createParams(params),
  })
  return data
}

const updateRule = async (rule = {}) => {
  const { data } = await axios.put(`${apiConfig.api.edge}/rules`, rule)
  return data
}

export default {
  getRuleData,
  updateRule,
}
