import axios from 'axios'
import { map } from 'lodash'

import apiConfig from '../../../config/apiConfig'

const getCarrierData = async () => {
  const { data } = await axios.get(`${apiConfig.api.edge}/carriers/group`)
  return data
}

const updateCarrierData = async (scac, payload) => {
  const { data } = await axios.put(
    `${apiConfig.api.edge}/carriers/${scac}`,
    payload,
  )
  return data
}

const createCarrier = async (requestParams, businessPartnerName, email) => {
  const {
    scac,
    api_bid,
    api_rate,
    manual_auction,
    max_wins_per_start_date,
    auction_eligible_load_sub_categories,
    expense_load_eligible_locations,
  } = requestParams

  const payload = {
    scac,
    active: true,
    api_bid,
    api_rate,
    manual_auction,
    carrier_contacts: {
      business_partner_contacts: [
        {
          name: businessPartnerName,
        },
      ],
    },
    carrier_details: {
      auction_details: {
        max_wins_per_start_date,
      },
      auction_eligible_load_categories: [],
      auction_eligible_load_sub_categories: map(
        auction_eligible_load_sub_categories,
        (selection) => selection.value,
      ),
      expense_locations: expense_load_eligible_locations,
    },
    create_by: email,
  }
  await axios.post(`${apiConfig.api.edge}/carriers`, payload)
}

export default {
  getCarrierData,
  updateCarrierData,
  createCarrier,
}
