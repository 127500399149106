import axios from 'axios'

import apiConfig from '../../config/apiConfig'

const getCriteria = async (criteriaId) => {
  const { data } = await axios.get(
    `${apiConfig.api.edge}/criteria/${criteriaId}`,
  )
  return data
}

export default {
  getCriteria,
}
