import { useState, useEffect, useMemo, useCallback } from 'react'
import axios from 'axios'
import { chain, isEmpty, reduce } from 'lodash'

import { Chip, ToastProvider } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CheckCircleIcon,
  CancelCircleIcon,
} from '@enterprise-ui/icons'

import { NovaTable, ProgressOverlay } from '@dlm/common'

import CarrierEligibilityEdit from './components/CarrierEligibilityEdit'
import CarrierEligibilityCreate from './components/CarrierEligibilityCreate'

import useUser from '../../common/hooks/useUser'
import carrierEligibilityService from './services/carrierEligibilityService'

import apiConfig from '../../config/apiConfig'
import { startCase } from '../../common/util/stringUtil'

export const defaultColumnDefs = [
  {
    headerName: 'Business Partner Name',
    field: 'business_partner_name',
    isRowHeader: true,
    width: 150,
  },
  {
    headerName: 'SCAC',
    field: 'scac',
    width: 50,
  },
  {
    headerName: 'API Rating',
    field: 'api_rating',
    width: 60,
  },
  {
    headerName: 'API Auction',
    field: 'api_auction',
    width: 60,
  },
  {
    headerName: 'Manual Auction',
    field: 'manual_auction',
    width: 60,
  },
  {
    headerName: 'Max Wins Per Day',
    field: 'max_wins_per_day',
    width: 70,
  },
  {
    headerName: 'Auction Eligible Categories',
    field: 'auction_eligible_categories',
  },
  {
    headerName: 'Expense Load Eligible Locations',
    field: 'expense_load_eligible_locations',
  },
]

const mapCarrierData = (carrierData = {}, isAdmin, onRefresh) => {
  const toIcon = (value) => {
    const properties = value
      ? { icon: CheckCircleIcon, color: 'green' }
      : { icon: CancelCircleIcon, color: 'red' }

    return {
      cellValue: value,
      cellDisplay: (
        <EnterpriseIcon icon={properties.icon} color={properties.color} />
      ),
    }
  }
  return reduce(
    carrierData,
    (result, carriers) => {
      carriers?.forEach((carrier, index) => {
        result.push({
          edit_button:
            isAdmin &&
            (index === 0
              ? {
                  cellValue: null,
                  cellDisplay: (
                    <CarrierEligibilityEdit
                      carriers={carriers}
                      onRefresh={onRefresh}
                    />
                  ),
                }
              : ''),
          business_partner_name: index === 0 ? carrier.name : '',
          scac: carrier.scac,
          api_rating: toIcon(carrier.api_rate),
          api_auction: toIcon(carrier.api_bid),
          manual_auction: toIcon(carrier.manual_auction),
          max_wins_per_day:
            carrier.carrier_details?.auction_details?.max_wins_per_start_date ??
            'N/A',
          auction_eligible_categories: {
            cellValue: null,
            cellDisplay: (
              <>
                {chain(
                  carrier.carrier_details?.auction_eligible_load_sub_categories,
                )
                  .omitBy(isEmpty)
                  .map((subcategory, i) => (
                    <Chip key={i} color="success" size="dense">
                      {startCase(subcategory)}
                    </Chip>
                  ))
                  .value()}
              </>
            ),
          },
          expense_load_eligible_locations:
            carrier.carrier_details?.expense_locations?.join(', '),
        })
      })
      return result
    },
    [],
  )
}

const CarrierEligibility = () => {
  const {
    accessToken,
    access: { isAdmin },
  } = useUser()

  // Configure any axios interceptors here
  // Usually we set interceptors globally, but this needs to be inside the component to work with federation
  axios.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = apiConfig.api.key
    // Usually populated by praxis by default, but doesn't work if accessed from parent mfe app
    config.headers['Authorization'] =
      accessToken && accessToken.includes('Bearer')
        ? accessToken
        : `Bearer ${accessToken}`
    return config
  })

  const makeToast = ToastProvider.useToaster()

  const [carrierData, setCarrierData] = useState(null)
  const [carrierCount, setCarrierCount] = useState(0)
  const [inProgress, setInProgress] = useState(false)

  const fetchCarrierData = useCallback(() => {
    setInProgress(true)
    carrierEligibilityService
      .getCarrierData()
      .then((data) => {
        setCarrierData(data)
        setCarrierCount(data.length)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching carrier data',
        })
      })
      .finally(() => {
        setInProgress(false)
      })
  }, [makeToast])

  const rowData = useMemo(
    () => mapCarrierData(carrierData, isAdmin, fetchCarrierData),
    [carrierData, isAdmin, fetchCarrierData],
  )

  useEffect(() => {
    fetchCarrierData()
  }, [fetchCarrierData])

  const columnDefs = []
  if (isAdmin) {
    columnDefs.push({
      headerName: 'Edit',
      field: 'edit_button',
      width: 60,
    })
  }
  columnDefs.push(...defaultColumnDefs)

  return (
    !isEmpty(rowData) && (
      <form onSubmit={(e) => e.preventDefault()}>
        <ProgressOverlay inProgress={inProgress} />
        <NovaTable
          name="Carrier Eligibility"
          aria-label="Carrier Eligiblity Table"
          showHeader
          scrollableWidth={false}
          columnDefs={columnDefs}
          rowData={rowData}
          rowCount={carrierCount}
          pageNum={1}
          pageSize={carrierCount}
          onPaginationChange={() => {}}
          tableActions={
            <CarrierEligibilityCreate
              carriers={carrierData}
              onRefresh={fetchCarrierData}
            />
          }
          // TODO: Enable pagination. Backend currently doesn't support it
          // pagination={{
          //   initialPageSize: 3,
          //   pageSizeOptions: [1, 3, 5, 10],
          //   type: "client",
          // }}
        />
      </form>
    )
  )
}

export default CarrierEligibility
