import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import {
  Button,
  Card,
  Divider,
  Drawer,
  Grid,
  Heading,
  Layout,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelCircleIcon,
  PencilIcon,
  SaveIcon,
} from '@enterprise-ui/icons'

import {
  ConfirmActionModal,
  FormRadio,
  FormTextArea,
  FormToggle,
  ProgressOverlay,
} from '@dlm/common'

import auctionConfigurationEditService from './services/auctionConfigurationEditService'

import { startCase } from '../../common/util/stringUtil'

const AuctionConfigurationEdit = ({ id, onRefresh }) => {
  const [ruleData, setRuleData] = useState({})
  const [inProgress, setInProgress] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmModalType, setConfirmModalType] = useState('exit')
  const makeToast = ToastProvider.useToaster()
  const [rule, type] = id.split('-')

  const [isEditPageVisible, setIsEditPageVisible] = useState(false)
  const openEditPage = () => setIsEditPageVisible(true)
  const closeEditPage = () => setIsEditPageVisible(false)

  const defaultValues = useMemo(() => {
    return {
      include_flag: ruleData?.rule_values?.include_flag
        ? 'includes'
        : 'excludes',
      value_list: ruleData?.rule_values?.value_list?.join(', '),
      active: ruleData?.active_flag,
    }
  }, [ruleData])

  const formContext = useForm({
    mode: 'onTouched',
    shouldUnregister: true,
    defaultValues: defaultValues,
  })

  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = formContext

  useEffect(() => {
    if (isEditPageVisible) {
      setInProgress(true)
      auctionConfigurationEditService
        .getRuleData({ name: rule, type })
        .then((resp) => {
          if (resp.length === 0) {
            makeToast({
              type: 'error',
              heading: 'Error',
              message: `Could not find rule config data for rule ${rule} ${type}`,
            })
          } else {
            setRuleData(resp[0])
          }
        })
        .catch(() => {
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error fetching rule config data',
          })
        })
        .finally(() => {
          setInProgress(false)
        })
    }
  }, [setRuleData, setInProgress, makeToast, rule, type, isEditPageVisible])

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const closeModal = () => {
    setShowConfirmModal(false)
  }

  const saveUpdates = (values) => {
    closeModal()
    auctionConfigurationEditService
      .updateRule({
        ...ruleData,
        active_flag: values.active,
        rule_values: {
          include_flag: values.include_flag === 'includes',
          value_list: values.value_list.split(/[\s,]+/),
        },
      })
      .then(() => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Rule successfully updated',
        })
        onRefresh()
        returnToAuctionConfiguration()
      })
      .catch((e) => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: `Error saving rule data ${e}`,
        })
      })
  }

  const resetForm = () => {
    closeModal()
    reset(defaultValues)
  }

  const returnToAuctionConfiguration = () => {
    closeModal()
    closeEditPage()
  }

  const confirmModalMetadata = {
    reset: {
      headingText: 'Reset form?',
      challengeText: 'All unsaved changes will be lost.',
      confirmButtonText: 'Reset',
      cancelButtonText: 'Cancel',
      confirmButtonAction: resetForm,
      cancelButtonAction: closeModal,
    },
    exit: {
      headingText: 'Return to Auction Configuration?',
      challengeText: 'All unsaved changes will be lost.',
      confirmButtonText: 'Return',
      cancelButtonText: 'Cancel',
      confirmButtonAction: returnToAuctionConfiguration,
      cancelButtonAction: closeModal,
    },
    save: {
      headingText: 'Are you sure you want to save your changes?',
      challengeText:
        'This action is not reversible and will go into effect immediately.',
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      confirmButtonAction: handleSubmit(saveUpdates),
      cancelButtonAction: closeModal,
    },
  }

  const handleResetButtonClicked = () => {
    setConfirmModalType('reset')
    setShowConfirmModal(true)
  }

  const handleSaveButtonClicked = () => {
    setConfirmModalType('save')
    setShowConfirmModal(true)
  }

  const handleReturnButtonClicked = () => {
    if (isDirty) {
      setConfirmModalType('exit')
      setShowConfirmModal(true)
    } else {
      returnToAuctionConfiguration()
    }
  }

  return (
    <>
      <ProgressOverlay inProgress={inProgress} />
      <Button
        type="ghost"
        iconOnly
        aria-label={`Auction Configuration Edit Button for ${startCase(rule)}`}
        onClick={openEditPage}
        className="table-edit-button"
      >
        <EnterpriseIcon icon={PencilIcon} />
      </Button>
      <Drawer
        isVisible={isEditPageVisible}
        hasOverlay={false}
        onRequestClose={handleReturnButtonClicked}
        headingText={
          <Button
            className="hc-ma-dense"
            type="primary"
            onClick={handleReturnButtonClicked}
          >
            Return to Auction Configuration
          </Button>
        }
        xs={12}
        style={{ height: 'calc(100vh - 56px)', top: '56px' }}
      >
        {isEditPageVisible && (
          <Layout.Body data-testid="auctionConfigurationEdit" includeRail>
            <Grid.Container>
              <Grid.Item xs={12}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Card className="hc-pa-expanded">
                    <Grid.Container justify="space-between">
                      <Grid.Item>
                        <Heading size={3}>
                          Rule: {startCase(ruleData?.rule)}
                        </Heading>
                      </Grid.Item>
                      <Grid.Item>
                        <Grid.Container spacing="dense">
                          <Grid.Item>
                            <Tooltip content="Save Changes" location="top">
                              <Button
                                iconOnly
                                type="ghost"
                                disabled={!isDirty || !isEmpty(errors)}
                                onClick={handleSaveButtonClicked}
                                aria-label="Save Changes"
                              >
                                <EnterpriseIcon icon={SaveIcon} size="lg" />
                              </Button>
                            </Tooltip>
                          </Grid.Item>
                          <Grid.Item>
                            <Tooltip content="Reset Form" location="top">
                              <Button
                                iconOnly
                                type="ghost"
                                onClick={handleResetButtonClicked}
                                disabled={!isDirty}
                                aria-label="Reset Form"
                              >
                                <EnterpriseIcon icon={CancelCircleIcon} />
                              </Button>
                            </Tooltip>
                          </Grid.Item>
                        </Grid.Container>
                      </Grid.Item>
                      <Grid.Item xs={12}>
                        <Divider />
                      </Grid.Item>
                      <Grid.Item xs={12}>
                        <Heading size={4}>
                          Type: {startCase(ruleData?.rule_type)}
                        </Heading>
                      </Grid.Item>
                      <Grid.Item xs={12}>
                        <FormRadio
                          className="horizontal-radio"
                          formContext={formContext}
                          name="include_flag"
                          options={[
                            {
                              value: 'includes',
                              label: 'Includes',
                            },
                            {
                              value: 'excludes',
                              label: 'Excludes',
                            },
                          ]}
                        />
                      </Grid.Item>
                      <Grid.Item xs={12}>
                        <FormTextArea
                          name="value_list"
                          label="Value List"
                          placeholder="Please provide a comma separated list."
                          formContext={formContext}
                        />
                      </Grid.Item>
                      <Grid.Item xs={12}>
                        <FormToggle
                          name="active"
                          label="Active"
                          formContext={formContext}
                        />
                      </Grid.Item>
                    </Grid.Container>
                  </Card>
                </form>
              </Grid.Item>
            </Grid.Container>
          </Layout.Body>
        )}
      </Drawer>
      <ConfirmActionModal
        isVisible={showConfirmModal}
        {...confirmModalMetadata[confirmModalType]}
      />
    </>
  )
}

export default AuctionConfigurationEdit
