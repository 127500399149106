import { useCallback, useMemo } from 'react'

import EnterpriseIcon, {
  CancelCircleIcon,
  CheckCircleIcon,
} from '@enterprise-ui/icons'

import { NovaTable } from '@dlm/common'

import AuctionConfigurationEdit from '../../AuctionConfigurationEdit/AuctionConfigurationEdit'

import useUser from '../../../common/hooks/useUser'

import { startCase } from '../../../common/util/stringUtil'
import { toWrappedText } from '../../../common/util/wrappedTextCell'

export const defaultColumnDefs = [
  {
    headerName: 'Type',
    field: 'rule_type',
    width: 400,
    sortable: true,
  },
  {
    headerName: 'Rule',
    field: 'rule',
    isRowHeader: true,
  },
  {
    headerName: 'Description',
    field: 'short_description',
    width: 350,
  },
  {
    headerName: 'Includes',
    field: 'includes',
    width: 130,
  },
  {
    headerName: 'Value List',
    field: 'value_list',
    width: 550,
  },
  {
    headerName: 'Active',
    field: 'active_flag',
    width: 80,
  },
]

const AuctionConfigurationTable = ({
  ruleData,
  ruleCount,
  onRefresh,
  ...restProps
}) => {
  const {
    access: { isAdmin },
  } = useUser()

  const mapRuleData = useCallback(
    (rules = []) => {
      const toIcon = (value) => {
        const properties = value
          ? { icon: CheckCircleIcon, color: 'green' }
          : { icon: CancelCircleIcon, color: 'red' }

        return {
          cellValue: value,
          cellDisplay: (
            <EnterpriseIcon icon={properties.icon} color={properties.color} />
          ),
        }
      }

      const mappedRules = []

      rules?.forEach((rule) => {
        mappedRules.push({
          edit_button: isAdmin && {
            cellValue: null,
            cellDisplay: (
              <AuctionConfigurationEdit
                id={`${rule.rule}-${rule.rule_type}`}
                onRefresh={onRefresh}
              />
            ),
          },
          rule: toWrappedText(startCase(rule.rule)),
          rule_type: toWrappedText(rule.rule_type),
          includes: toWrappedText(
            rule.rule_values?.include_flag ? 'Included' : 'Excluded',
          ),
          value_list: toWrappedText(rule.rule_values?.value_list.join(', ')),
          short_description: toWrappedText(rule.short_description),
          active_flag: toIcon(rule.active_flag),
        })
      })

      return mappedRules
    },
    [isAdmin, onRefresh],
  )

  const rowData = useMemo(() => mapRuleData(ruleData), [ruleData, mapRuleData])

  const columnDefs = []
  if (isAdmin) {
    columnDefs.push({
      headerName: 'Edit',
      field: 'edit_button',
      width: 75,
    })
  }
  columnDefs.push(...defaultColumnDefs)

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <NovaTable
        name="Auction Configuration"
        aria-label="Auction Configuration Table"
        showHeader
        scrollableWidth={false}
        columnDefs={columnDefs}
        rowData={rowData}
        rowCount={ruleCount}
        // TODO - Remove pagination once supported
        pageNum={1}
        pageSize={ruleCount}
        onPaginationChange={() => {}}
        {...restProps}
      />
    </form>
  )
}

export default AuctionConfigurationTable
